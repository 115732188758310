
<template>
  <div id="skills">
    <h2>Skills</h2>
    <Tile :items="skills"/>
    <h4>Learning</h4>
    <Tile :items="learning"/>
  </div>
</template>

<script>
import Tile from '../components/Tile.vue'

export default {
  name: 'Skills',
  components: {
    Tile
  },
  data() {
    return {
      skills: [
        {
          name: 'git',
          fileName: 'git-original',
          prettyName: 'Git',
        },
        {
          name: 'ansible',
          fileName: 'ansible-original',
          prettyName: 'Ansible',
        },
        {
          name: 'docker',
          fileName: 'docker-original',
          prettyName: 'Docker',
        },
        {
          name: 'amazonwebservices',
          fileName: 'amazonwebservices-original',
          prettyName: 'AWS',
        },
        {
          name: 'digitalocean',
          fileName: 'digitalocean-original',
          prettyName: 'Digital Ocean',
        },
        {
          name: 'apachekafka',
          fileName: 'apachekafka-original',
          prettyName: 'Apache Kafka',
        },
        {
          name: 'nginx',
          fileName: 'nginx-original',
          prettyName: 'NGiNX',
        },
        {
          name: 'github',
          fileName: 'github-original',
          prettyName: 'Github',
        },
        {
          name: 'gitlab',
          fileName: 'gitlab-original',
          prettyName: 'GitLab',
        },
        {
          name: 'redis',
          fileName: 'redis-original',
          prettyName: 'Redis',
        },
        {
          name: 'mysql',
          fileName: 'mysql-original',
          prettyName: 'MySQL',
        },
        {
          name: 'mongodb',
          fileName: 'mongodb-original',
          prettyName: 'MongoDB',
        },
        {
          name: 'arduino',
          fileName: 'arduino-original',
          prettyName: 'Arduino',
        },
        /* { */
        /*   name: 'blender', */
        /*   fileName: 'blender-original', */
        /*   prettyName: 'Blender', */
        /* }, */
        {
          name: 'raspberrypi',
          fileName: 'raspberrypi-original',
          prettyName: 'Raspberry Pi',
        },
      ],
      learning: [
        {
          name: 'kubernetes',
          fileName: 'kubernetes-plain',
          prettyName: 'Kubernetes',
        },
        {
          name: 'terraform',
          fileName: 'terraform-original',
          prettyName: 'Terraform',
        },
      ]
    }
  }
}
</script>

<style scoped>
.reader {
  width: 100%;
  padding: 30px 30px;
  border-radius: 0 0 2rem 0;
}

</style>
